// stylelint-disable declaration-no-important

//
// Left side navigation
//

.hds-sidebar {
  order: 0;
  padding-right: 0;
  padding-left: 0;
  background-color: $hds-light-gray;
  // border-bottom: 1px solid rgba(0, 0, 0, .1);

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 3.125rem;
      z-index: 1000;
      height: calc(100vh - 3.125rem);
    }
    // border-right: 1px solid rgba(0, 0, 0, .1);
    width: 180px;
  }

  @include media-breakpoint-up(xl) {
    flex: 0 1 320px;
  }
}
.hds-nav-title{
  height: 4.375rem;
  padding: 0;
  font-weight: 700;
  line-height: 4.375rem;
  color: #3b3b3b;
  text-indent: 2rem;

  background: #d9dee4;

}

.hds-links {
  padding-top: 0;
  padding-bottom: 0;
  // margin-right: -15px;
  // margin-left: -15px;

  .nav{
    flex-wrap: nowrap;
  }

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      max-height: calc(100vh - 7.5rem);
      overflow-y: auto;
    }
  }

  // Override collapse behaviors
  @include media-breakpoint-up(md) {
    display: block !important;
  }
  .nav-link{
    display: block;
    height: 2.5rem;
    padding: 0;
    overflow: hidden;
    font-size: .75rem;
    font-weight: 400;
    line-height: 2.5rem;

    color: #373d41;
    text-overflow: ellipsis;

    &:hover{
      background-color: #f4f6f8;
    }

    .item-icon{
      display: inline-block;
      width: 1.875rem;
      height: 2.5rem;
      line-height: 2.5rem;
      text-align: center;
      i{
        display: inline-block;
      }
    }
    .item-title{
      display: inline-block;
      height: 2.5rem;
      line-height: 2.5rem;
    }

  }
  .hds-triangle-icon{
    display: inline-block;
  }

  .hds-triangle-icon-open::before{
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: -.4rem;
    vertical-align: .2rem;
    content: "";
    border-top: .25rem solid;
    border-right: .25rem solid transparent;
    border-bottom: 0;
    border-left: .25rem solid transparent;
  }
  .hds-triangle-icon-close::before{
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: -.4rem;
    content: "";
    border-top: .25rem solid transparent;
    border-right: 0;
    border-bottom: .25rem solid transparent;
    border-left: .25rem solid;
  }
  .nav-item{
    .nav-item .nav-link{
      padding-left: 4px;
      color: #73777a;
    }
    .active{
      background-color: #fff;
    }
  }
}
