//
// Footer
//

.hds-footer {
  font-size: 85%;
  text-align: center;
  background-color: #f7f7f7;

  a {
    font-weight: 500;
    color: $gray-700;

    &:hover,
    &:focus {
      color: $hds-cyan;
    }
  }

  p {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    text-align: left;
  }
}

.hds-footer-links {
  padding-left: 0;
  margin-bottom: 1rem;

  li {
    display: inline-block;

    + li {
      margin-left: 1rem;
    }
  }
}
