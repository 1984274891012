.hds-navbar {
  min-height: 3.125rem;
  padding: 0 0 0 .5rem;
  background-color: #373d41;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .hds-navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hds-navbar .navbar-nav .user-box,
.hds-navbar .navbar-nav .nav-link {
  height: 3.125rem;
  padding: 0 .75rem;
  line-height: 3.125rem;
  color: #fff;
}

.hds-navbar .navbar-nav .user-box.active, .hds-navbar .navbar-nav .user-box:hover,
.hds-navbar .navbar-nav .nav-link.active,
.hds-navbar .navbar-nav .nav-link:hover {
  color: #fff;
  background-color: #2a2f32;
}

.hds-navbar .navbar-nav .user-box.active,
.hds-navbar .navbar-nav .nav-link.active {
  font-weight: 400;
}

.hds-navbar .navbar-nav .nav-link {
  border-right: 1px solid #2a2f32;
}

.hds-navbar .navbar-nav .user-box {
  display: inline-block;
}

.hds-navbar .navbar-nav .user-box .nav-user-avatar {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.125rem;
}

.hds-navbar .navbar-nav .hds-nav-item-dropdow .dropdown-menu {
  margin: 0;
  border-top: 0;
  border-radius: 0;
}

.hds-navbar .navbar-nav .hds-nav-item-dropdow:hover .dropdown-menu {
  display: block;
}

.hds-navbar .navbar-nav .dropdown-item {
  height: 2rem;
  padding: 0 1.5rem;
  line-height: 2rem;
}

.hds-navbar .navbar-nav-svg {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
}

.hds-navbar .dropdown-menu {
  font-size: .75rem;
  color: #333;
}

.hds-navbar .dropdown-item.active {
  font-weight: 500;
  color: #212529;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: .4rem .6rem;
  background-size: .75rem .75rem;
}

.hds-module-header {
  position: relative;
  top: 0;
  left: 0;
  height: 4.375rem;
  padding-left: 1.25rem;
  margin: -1rem -1rem 1rem;
  background: #f9fafb;
  border-bottom: 1px solid #eee;
}

.hds-module-header .header-flag {
  display: inline-block;
  width: .25rem;
  height: 1.25rem;
  vertical-align: middle;
  background-image: linear-gradient(0deg, #33aaf7, #00c4e2);
  border-radius: .125rem;
}

.hds-module-header .title {
  display: inline-block;
  height: 4.375rem;
  font-size: 1.125rem;
  line-height: 4.375rem;
  color: #373d41;
  vertical-align: middle;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.hds-alert {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 20;
  min-width: 200px;
  margin-bottom: 0;
  margin-left: -200px;
  border: medium none;
}

.hds-alert .spinner,
.hds-alert .spinner-small {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -20px -20px 0 0;
  border: 2px solid #fff;
  border-top: 2px solid #00c1de;
  border-radius: 100%;
}

.hds-alert .fade.in .spinner,
.hds-alert .fade.in .spinner-small {
  -webkit-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
}

#skippy {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: #0fb2cc;
  outline: 0;
}

#skippy .skiplink-text {
  padding: .5em;
  outline: 1px dotted;
}

.hds-sidebar {
  -ms-flex-order: 0;
  order: 0;
  padding-right: 0;
  padding-left: 0;
  background-color: #eaedf1;
}

@media (min-width: 768px) {
  .hds-sidebar {
    width: 180px;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .hds-sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 3.125rem;
      z-index: 1000;
      height: calc(100vh - 3.125rem);
    }
  }
}

@media (min-width: 1200px) {
  .hds-sidebar {
    -ms-flex: 0 1 320px;
    flex: 0 1 320px;
  }
}

.hds-nav-title {
  height: 4.375rem;
  padding: 0;
  font-weight: 700;
  line-height: 4.375rem;
  color: #3b3b3b;
  text-indent: 2rem;
  background: #d9dee4;
}

.hds-links {
  padding-top: 0;
  padding-bottom: 0;
}

.hds-links .nav {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .hds-links {
      max-height: calc(100vh - 7.5rem);
      overflow-y: auto;
    }
  }
}

@media (min-width: 768px) {
  .hds-links {
    display: block !important;
  }
}

.hds-links .nav-link {
  display: block;
  height: 2.5rem;
  padding: 0;
  overflow: hidden;
  font-size: .75rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: #373d41;
  text-overflow: ellipsis;
}

.hds-links .nav-link:hover {
  background-color: #f4f6f8;
}

.hds-links .nav-link .item-icon {
  display: inline-block;
  width: 1.875rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
}

.hds-links .nav-link .item-icon i {
  display: inline-block;
}

.hds-links .nav-link .item-title {
  display: inline-block;
  height: 2.5rem;
  line-height: 2.5rem;
}

.hds-links .hds-triangle-icon {
  display: inline-block;
}

.hds-links .hds-triangle-icon-open::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: -.4rem;
  vertical-align: .2rem;
  content: "";
  border-top: .25rem solid;
  border-right: .25rem solid transparent;
  border-bottom: 0;
  border-left: .25rem solid transparent;
}

.hds-links .hds-triangle-icon-close::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: -.4rem;
  content: "";
  border-top: .25rem solid transparent;
  border-right: 0;
  border-bottom: .25rem solid transparent;
  border-left: .25rem solid;
}

.hds-links .nav-item .nav-item .nav-link {
  padding-left: 4px;
  color: #73777a;
}

.hds-links .nav-item .active {
  background-color: #fff;
}

.hds-footer {
  font-size: 85%;
  text-align: center;
  background-color: #f7f7f7;
}

.hds-footer a {
  font-weight: 500;
  color: #495057;
}

.hds-footer a:hover, .hds-footer a:focus {
  color: #0fb2cc;
}

.hds-footer p {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .hds-footer {
    text-align: left;
  }
}

.hds-footer-links {
  padding-left: 0;
  margin-bottom: 1rem;
}

.hds-footer-links li {
  display: inline-block;
}

.hds-footer-links li + li {
  margin-left: 1rem;
}
/*# sourceMappingURL=hds.min.css.map */