//
// Main navbar
//

.hds-navbar {
  min-height: 3.125rem;
  padding: 0 0 0 .5rem;
  background-color: $hds-dark;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .05), inset 0 -1px 0 rgba(0, 0, 0, .1);

  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: 1020; // same to bootstrap zindex-sticky
  }

  .navbar-nav {
    .user-box,
    .nav-link {
      height: 3.125rem;
      padding: 0 .75rem;
      line-height: 3.125rem;
      color: $white;

      &.active,
      &:hover {
        color: $white;
        background-color: #2a2f32;
      }

      &.active {
        font-weight: 400;
      }
    }
    .nav-link{
      border-right: 1px solid #2a2f32;
    }
    .user-box{
      display: inline-block;
      .nav-user-avatar{
        width: 2.25rem;//36px
        height: 2.25rem;
        border-radius: 1.125rem;//18px
      }
    }
    .hds-nav-item-dropdow{
      .dropdown-menu{
        margin: 0;
        border-top: 0;
        border-radius: 0;
      }
    }
    .hds-nav-item-dropdow:hover{
      .dropdown-menu{
        display: block;
      }
    }
    .dropdown-item{
      height: 2rem;
      padding: 0 1.5rem;
      line-height: 2rem;

    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }

  .dropdown-menu {
    font-size: .75rem;
    color: #333;
  }

  .dropdown-item.active {
    font-weight: 500;
    color: $gray-900;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: .4rem .6rem;
    background-size: .75rem .75rem;
  }
}
